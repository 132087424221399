import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">20 X 20 / 上半場</h2>
          <h1 className="main_title">林長叡</h1>
        </div>

        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_5">
                <div className="context">
                  <p>林長叡界定自己多次與藝術家合作，屬於溝通協調與合作的角色。針對公共藝術和社會住宅，他認為現今藝術的表現手法非常多元，透過他的眼光跟想像可以有許多不同的嘗試與創作，讓更多的可能發生。</p>
                  <p>社宅的公共空間，不管是室內或戶外，可能性非常多元，傳統思維會選擇大廳或者廣場中央的地方去設置公共藝術，然而還有很多曖昧空間可以有更好發揮。林長叡認為公共藝術與這些空間牽涉環境因素與條件，要以整體性去思考。 feeder、養蜂場、雨水花園、一個擁有動植物或昆蟲的小型自然博物館。如環境藝術家Vaughn Bell與植物學家Pati Vitt的計畫─Local Home，將雨水花園設計轉化為作品展出。或東京都的摩天樓種蜜園，利用透明蜂巢可觀察到另一個昆蟲社群，體覺除了人類還有另外的動物社群生活於一個共同環境中。沈伯丞再舉《Sierra Remote Observatories》一個於社區大樓頂端設置望遠鏡的藝術計畫，提供住戶觀察接觸該生活區域、不同季節的星空；而展覽《Art as Aftershock》將地震紀錄轉化成圖像、聲音，讓人聽見、看見該場域曾發生大地震動的變化。透過這些計畫進一步思考、感覺自然與氣候，或許可以帶來觀看生活空間不同的視角，也可以是凝聚社區共同意識的方式。</p>
                  <p>藝術也可以紀錄生活圈的狀態，ILYA 他提出五項因素進而可以思考公共藝術的整體性。</p>
                  <p className="onset_right_1">第一項因素是環境，例如戶外的風、雨、水、陽光等自然物理條件。對環境特性要先調查了解再來設定公共藝術。第二個因素是背景──建築的環境背景，就是空間的整體畫面和諧。第三個因素是建築紋理，公共藝術要考慮如何呼應建築材料的運用構成方式或是顏色形成的紋理。第四個要考量到人文，社宅公共藝術要和在地、現場的人對話，要考慮使用者每天回家看到他的感受。氣味、聲音，都是在環境中誘發感受的一部分。</p>
                  <p className="onset_right_1">最後透過分享藝術家ROGER 公共藝術並非單獨、獨立的存在，要思考整個區域的條件與需要，再考慮要以什麼樣的方式去誘發怎麼樣的感受。因此第五個因素是感受。感受這件事情很抽象，並不明顯卻充盈在所有的地方。藝術家應思考如何發揮創意誘發感受。第六個因素是JOIN，和民眾或社宅的人共創也很重要，要有團隊合作的概念。</p>
                  <p className="onset_right_1">最後透過分享藝術家ROGER 在環境裡，周邊配套其實很重要，例如燈具、街道家具、遊樂園，運動場、體育場，甚至是機房倉庫等都可以跟不同的藝術家結合創作。或許公共藝術創作者不是要強調自己作品，反而是要將之融入到環境裡面。</p>
                </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_1_3" className="next_page">
            <div className="next_title">鄭鴻旗</div>
            <div className="next_owner">20 X 20 / 上半場</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
